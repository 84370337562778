.button {
  background-color: var(--color-orange);
  border: none;
  border-radius: 2.8rem;
  padding: 1.6rem 2.4rem;
  color: var(--color-white);
  font-size: var(--font-size-s);
  line-height: var(--line-height-m);
  font-family: var(--default-font);
  cursor: pointer;

  &.fullWidthOnMobile {
    width: 100%;

    @media (--viewport-m) {
      width: auto;
    }
  }

  &.secondary {
    color: var(--color-blue);
    border: 1px solid var(--color-blue);
    background: none;
  }

  &.discrete {
    color: var(--color-blue);
    background: none;
  }

  &[disabled] {
    background-color: var(--color-darkblue-100);
  }

  & > div {
    margin-right: 0.8rem;
  }
}
