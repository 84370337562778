.input {
  outline: none;
  border: 1px solid var(--color-blue);
  border-radius: var(--border-radius);
  padding: 1rem 0.7rem;
  color: var(--color-darkblue-100);
  transition: 0.1s ease-out;
  width: 100%;
  appearance: none;

  &:focus + label {
    top: 0;
    transform: translateY(-50%) scale(0.9);
  }

  &:not(:placeholder-shown) + label {
    top: 0;
    transform: translateY(-50%) scale(0.9);
  }

  &:disabled {
    border: 1px solid var(--color-darkblue-20);
    background-color: var(--color-darkblue-5);
    color: var(--color-darkblue-20);

    & + .label {
      color: var(--color-darkblue-20);
    }

    & + .icon {
      color: var(--color-darkblue-20);
    }
  }
}

.label {
  position: absolute;
  font-size: var(--font-size-xs);
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--color-white);
  color: var(--color-blue);
  padding: 0 0.3rem;
  margin: 0 0.5rem;
  transition: 0.1s ease-out;
  transform-origin: left top;
  pointer-events: none;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;

  &.error {
    & > .input {
      border: 1px solid var(--color-red);
    }

    & > .icon {
      color: var(--color-red);
    }

    & > .label {
      color: var(--color-red);
    }
  }
}

.icon {
  color: var(--color-blue);
  position: absolute;
  top: 1.2rem;
  left: auto;
  right: 1.2rem;
  height: var(--font-size-xs);
  width: var(--font-size-xs);
  pointer-events: none;
}
