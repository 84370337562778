.container {
  margin-bottom: 1.6rem;
  padding: 0.8rem;
  border-radius: var(--border-radius);

  &.success {
    color: var(--color-green);
    border: 1px solid var(--color-green);
    background-color: var(--color-lightgreen);
  }

  &.error {
    color: var(--color-red);
    border: 1px solid var(--color-red);
    background-color: var(--color-lightred);
  }

  &.info {
    color: var(--color-darkblue-80);
    border: 1px solid var(--color-darkblue-80);
    background-color: var(--color-lightblue);
  }
}
