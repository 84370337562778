.forgotPassword {
  font-size: var(--font-size-xs);
  display: block;
  margin-left: auto;
}

.passwordErrorContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
