.titleContainer > * {
  margin: 0;
}

.title {
  color: var(--color-darkblue-100);
  margin-bottom: 0.8rem;
}

.dateFormat {
  margin: 0.8rem 0 0;
  font-size: var(--font-size-xs);
}
