.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (--viewport-m) {
    flex-direction: row;
    height: 100vh;
    align-items: center;
  }
}

.content {
  @media (--viewport-m) {
    max-width: 58rem;
    margin: auto;
  }

  & > * {
    margin-bottom: 4rem;
  }
}

.main {
  padding: 5.6rem 1.6rem 0;

  @media (--viewport-m) {
    flex: 2;
  }
}

.footer {
  position: relative;
  background-color: var(--color-lightblue);
  height: 15.6rem;
  width: 100%;

  @media (--viewport-m) {
    flex: 1;
    height: 100%;
  }

  & .img {
    width: 8rem;
    height: 13rem;
    position: absolute;
    top: -1.6rem;
    right: 5rem;

    @media (--viewport-m) {
      height: 30.8rem;
      width: 17.9rem;
      top: auto;
      bottom: 5.6rem;
      right: 5.6rem;
    }
  }
}
