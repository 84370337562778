.container h1,
.container h2,
.container h3,
.container h4 {
  color: var(--color-donkerblauw-100);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-m);
  margin: 0;
}

.container h1 {
  font-size: var(--font-size-xxl);

  @media (--viewport-m) {
    font-size: var(--font-size-xxxl);
  }
}

.container h2 {
  font-size: var(--font-size-l);

  @media (--viewport-m) {
    font-size: var(--font-size-xxl);
  }
}

.container h3 {
  font-size: var(--font-size-l);

  @media (--viewport-m) {
    font-size: var(--font-size-l);
  }
}

.container h4 {
  font-size: var(--font-size-m);

  @media (--viewport-m) {
    font-size: var(--font-size-l);
  }
}

.container h1:not(:last-child),
.container h2:not(:last-child),
.container h3:not(:last-child),
.container h4 :not(:last-child) {
  margin: 0 0 0.5em;
}

.container p {
  font-size: var(--font-size-m);
  line-height: var(--line-height-l);
  margin: 0;

  &:not(:last-child) {
    margin: 0 0 1.2em;
  }
}

.container ul,
.container ol {
  font-size: var(--font-size-m);
  line-height: var(--line-height-m);
  margin: 0;
}

.container ol:not(:last-child),
.container ul:not(:last-child) {
  margin: 0 0 1.2em;
}

.container a {
  color: var(--color-donkerblauw-60);
  transition: color 200ms ease-out;

  &:hover,
  &:focus {
    color: var(--color-donkerblauw-100);
  }
}

.center {
  text-align: center;
}
